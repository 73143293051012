<template>
  <div>
    <label
      v-if="props.label"
      :for="props.name"
      class="inline-flex items-center text-sm font-medium leading-6 text-gray-900 w-full"
      >{{ props.label
      }}<InformationCircleIcon
        v-if="tooltip"
        v-tooltip="tooltip"
        class="h-4 w-4 shrink-0 ml-1"
    /></label>
    <div :class="['relative rounded-md', { 'mt-1': props.label }]">
      <input
        :id="props.name"
        :value="props.value"
        :type="props.type"
        :name="props.name"
        :autocomplete="props.autocomplete"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :class="classStr"
        :aria-invalid="displayErrorMessage != null"
        :aria-describedby="ariaDescribedBy"
        data-cy="form-input"
        @input="onInput"
      />
      <div
        v-if="loading"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center"
      >
        <Loader aria-hidden="true" />
      </div>
      <div
        v-else-if="displayErrorMessage"
        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
      >
        <ExclamationCircleIcon
          class="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      </div>
    </div>
    <p
      v-if="displayErrorMessage"
      id="email-error"
      class="mt-2 text-sm text-red-600"
    >
      {{ displayErrorMessage }}
    </p>
    <p
      v-else-if="infoMessage"
      id="email-error"
      class="mt-2 text-sm text-gray-400"
    >
      {{ infoMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/20/solid";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { ValidationRule } from "~/types/shared";

const props = defineProps({
  label: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  autocomplete: {
    type: String,
    required: false,
    // Disable as MenuItems fail with Cannot read property 'length' of undefined
    default: "off",
  },
  placeholder: {
    type: String,
    required: false,
    default: undefined,
  },
  value: {
    type: [String, Number],
    required: false,
    default: undefined,
  },
  type: {
    type: String,
    required: false,
    default: "text",
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    required: false,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  errorMessage: {
    type: String,
    required: false,
    default: null,
  },
  tooltip: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
  lg: {
    type: Boolean,
    default: false,
  },
  xs: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  infoMessage: {
    type: String as PropType<string | null>,
    required: false,
    default: null,
  },
});

const { validate } = useValidation();
const displayErrorMessage = ref<string | null>(null);

onMounted(() => {
  if (props.errorMessage) {
    displayErrorMessage.value = props.errorMessage;
  }
});

watch(
  () => props.errorMessage,
  (newValue) => {
    displayErrorMessage.value = newValue;
  },
);

const classStr = computed(() => {
  const size = props.lg
    ? "text-sm py-2"
    : props.xs
      ? "text-xs py-0.5"
      : "text-sm py-1.5";
  const isValid =
    "block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " +
    "placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-400 sm:leading-6 " +
    "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 " +
    size;
  const isInvalid =
    "block w-full rounded-md border-0 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 " +
    "focus:ring-2 focus:ring-inset focus:ring-red-500 sm:leading-6 " +
    "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 " +
    size;

  return displayErrorMessage.value ? isInvalid : isValid;
});

const ariaDescribedBy = computed(() => {
  return displayErrorMessage.value ? "email-error" : undefined;
});

const emit = defineEmits(["input", "invalid"]);

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (props.rules.length <= 0 && props.errorMessage != null) {
    emit("input", target.value);
    displayErrorMessage.value = props.errorMessage;
    return;
  }
  let inputValid = true;
  for (const rule of props.rules) {
    const [isValid, errorMsgMaybe] = validate(target.value, rule);
    if (!isValid) {
      inputValid = false;
      if (errorMsgMaybe) {
        displayErrorMessage.value = errorMsgMaybe;
      }
      break;
    }
  }
  emit("input", target.value);
  if (inputValid) {
    emit("invalid", false);
    displayErrorMessage.value = null;
  } else {
    emit("invalid", true);
  }
};
</script>
